
@font-face {
    font-family: 'Trebuchet';
    src: url(/static/media/TrebuchetMS.d6c9e061.woff2) format('woff2'),
        url(/static/media/TrebuchetMS.25df0e65.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}
.popupbox {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    z-index: 1000;
}


.popupbox-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1002;
    background: none repeat scroll 0 0 #000;
}


.popupbox.is-active {
    opacity: 1;
    pointer-events: auto;
    -webkit-transition: none 0s ease 0s;
    transition: none 0s ease 0s;
}

.popupbox-wrapper {
    display: flex;
    flex-direction: column;
    z-index: 1003;
    position: relative;
    border-radius: 3px;
    overflow: hidden;
    max-width: 80%;
    min-width: 300px;
    /* height: 80%; */
    box-shadow: 0 0 20px rgba(0,0,0,.9);
    background-color: #fff;
}

.popupbox-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.carousel .control-dots {
    padding: 0;
}

.carousel-slider {
    /* height: 350px; */
}

.carousel-title {
    font-size: 1.2em;
    text-align: center;
    font-family: 'Trebuchet';
    padding: 5px;
}

.carousel .thumbs-wrapper {
    margin: 0px !important;
    overflow: hidden;
}

.lightbox-close-btn {
    margin-top: 1em;
    padding: 15px;
    text-align: right;
    font-size: 1em;
    text-transform: uppercase;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-width: 0;
    background-color: white;
}
